<template>
  <div>
    <div class="title">Change Password</div>
    <div class="all row mt-4">
      <div class="col-6">
        <p class="subtitle mt-3">Current Password</p>
        <b-form-input
          v-model="info.currentPassword"
          type="password"
          placeholder="Current password"
          class="shape col-9"
        ></b-form-input>
        <p class="subtitle mt-3">New Password</p>
        <b-form-input
          v-model="info.newPassword"
          type="password"
          placeholder="Password"
          class="shape col-9"
        ></b-form-input>
        <p class="subtitle mt-3">Confirm New Password</p>
        <b-form-input
          v-model="info.confirmPassword"
          type="password"
          placeholder="Confirm"
          class="shape col-9"
        ></b-form-input>
      </div>
    </div>
    <div class="button mb-5">
      <b-button variant="primary" class="blue" @click="doUpdate()">Update</b-button>
      <b-button variant="danger" class="red" @click="close()">Cancel</b-button>
    </div>
  </div>
</template>

<script>
import api from "../../api";
export default {
  data() {
    return {
      info: {},
      selected: null,
    };
  },
  methods: {
    close() {
      this.$bvModal.hide("changepassword");
    },
    makeToast(variant, header, textToShow) {
        this.$bvToast.toast(textToShow, {
          title: header ,
          variant: variant,
          toaster: 'b-toaster-top-center',
          solid: false
        })
    },
    doUpdate(){
      this.updatePassword();
    },
    getProfile() {
      api.loadAccount().then(
        (response) => {
          this.info = response.responseData;
        },
        (error) => {
          alert(JSON.stringify(error));
        }
      );
    },
    getSettings(){
      const objectForm = {
          oldPassword: this.info.currentPassword,
          newPassword: this.info.newPassword
        };
        return objectForm;
    },
    updatePassword() {
      if(this.info.newPassword!== this.info.confirmPassword){
        this.makeToast('danger','Oops! Something went wrong.', 'New password and confirm password do not match.');
      }
      else{
         const settings = this.getSettings();
        const form = JSON.stringify(settings);
        api.updatePassword(form).then(
        (response) => {
          this.info = response.responseData;
          this.$emit('update-password', 'Password updated successfully.');
          this.$bvModal.hide("changepassword");
        },
        (error) => {
          this.makeToast('danger','Oops! Something went wrong.',error.responseData);
        }
      );
      }
    },
  },
  mounted() {
    this.getProfile();
  },
};
</script>

<style scoped>
.all {
  margin-left: 60px;
}
.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  text-align: center;

  color: #23438e;
}
.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #23438e;
}
.shape {
  width: 480px;
  height: 56px;
}
.button {
  text-align: center;
}
.red {
  width: 250px;
  height: 50px;
  margin-left: 30px;

  background: #d31c1c;
  border-radius: 5px;
  margin-top: 50px;
}
.blue {
  margin-top: 50px;
  width: 250px;
  height: 50px;

  background: #23438e;
  border-radius: 5px;
}
</style>
