<template>
  <div>
    <div class="title">Profile</div>
    <div class="all row mt-4">
      <div class="col-6">
        <p class="subtitle mt-3">First Name</p>
        <b-form-input
          v-model="info.firstName"
          type="text"
          placeholder="First Name"
          class="shape col-9"
        ></b-form-input>
        <p class="subtitle mt-3">Email</p>
        <b-form-input
          v-model="info.email"
          :disabled="true"
          type="email"
          placeholder="Email"
          class="shape col-9"
        ></b-form-input>
        <p class="subtitle mt-3">Phone</p>
        <b-form-input
          v-model="info.phoneNumber"
          type="text"
          placeholder="Phone"
          class="shape col-9"
        ></b-form-input>
      </div>

      <div class="col-6">
        <p class="subtitle mt-3">Last Name</p>
        <b-form-input
          v-model="info.lastName"
          type="text"
          placeholder="Last Name"
          class="shape col-9"
        ></b-form-input>
        <p class="subtitle mt-3">Company</p>
        <b-form-input
          v-model="info.company"
          type="text"
          placeholder="Company"
          class="shape col-9"
        ></b-form-input>
        <p class="subtitle mt-3">Country</p>
        <b-form-input
          v-model="info.country"
          type="text"
          placeholder="Country"
          class="shape col-9"
        ></b-form-input>
      </div>
    </div>
    <div class="button mb-5">
      <b-button variant="primary" class="blue" @click="doUpdate()">Update</b-button>
      <b-button variant="danger" class="red" @click="close()">Cancel</b-button>
    </div>
  </div>
</template>

<script>
import api from "../../api";
export default {
  data() {
    return {
      info: {},
      selected: null,
    };
  },
  methods: {
    close() {
      this.$bvModal.hide("profile");
    },
    makeToast(textToShow) {
        this.$bvToast.toast(textToShow, {
          title: 'Oops! Something went wrong.' ,
          variant: 'warning',
          toaster: 'b-toaster-top-center',
          solid: false
        })
    },
    doUpdate(){
      this.updateProfile();
      this.$bvModal.hide("profile");
    },
    getProfile() {
      api.loadAccount().then(
        (response) => {
          this.info = response.responseData;
        },
        (error) => {
          alert(JSON.stringify(error));
        }
      );
    },
    getSettings(){
      const objectForm = {
          firstName: this.info.firstName,
          lastName: this.info.lastName,
          email: this.info.email,
          company: this.info.company,
          country: this.info.country,
          phoneNumber: this.info.phoneNumber
        };
        return objectForm;
    },
    updateProfile() {
      const settings = this.getSettings();
      const form = JSON.stringify(settings);
      api.updateProfile(form).then(
        (response) => {
          this.info = response.responseData;
          this.$emit(
        "update-profile",settings);
        },
        (error) => {
          this.makeToast(error.responseData);
        }
      );
    },
  },
  mounted() {
    this.getProfile();
  },
};
</script>

<style scoped>
.all {
  margin-left: 60px;
}
.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  text-align: center;

  color: #23438e;
}
.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #23438e;
}
.shape {
  width: 480px;
  height: 56px;
}
.button {
  text-align: center;
}
.red {
  width: 250px;
  height: 50px;
  margin-left: 30px;

  background: #d31c1c;
  border-radius: 5px;
  margin-top: 50px;
}
.blue {
  margin-top: 50px;
  width: 250px;
  height: 50px;

  background: #23438e;
  border-radius: 5px;
}
</style>
