<template>
  <div>
    <sidebar />
    <div class="main">
      <upbar title="Account" class="pb-5" /> <br />
      <div class="title">Profile</div>
      <div class="plus mt-5" v-b-modal.profile>
        <div class="plusIn">
          <img src="../assets/edit.png" class="iconPlus" />
          <span> Edit Profile </span>
        </div>
      </div>
      <b-button size="sm" class="plus mt-5" v-b-modal.changepassword>
      <b-icon icon="lock" aria-hidden="true"></b-icon> Change password
    </b-button>
    
      <div class="top row">
        <div class="col-6">
          <div class="row mt-4">
            <div class="col-3 small-title">First name</div>
            <b-form-input
              :disabled="true"
              v-model="profile.firstName"
              type="text"
              placeholder="First name"
              class="shape col-5"
            ></b-form-input>
          </div>
        </div>
        <div class="col-6">
          <div class="row mt-4">
            <div class="col-3 small-title">Last name</div>
            <b-form-input
              :disabled="true"
              v-model="profile.lastName"
              type="text"
              placeholder="Last name"
              class="shape col-5"
            ></b-form-input>
          </div>
        </div>
        <div class="col-6">
          <div class="row mt-4">
            <div class="col-3 small-title">Email</div>
            <b-form-input
              :disabled="true"
              v-model="profile.email"
              type="text"
              placeholder="Email"
              class="shape col-5"
            ></b-form-input>
          </div>
        </div>
    
        <div class="col-6">
          <div class="row mt-4">
            <div class="col-3 small-title">Company</div>
            <b-form-input
              :disabled="true"
              v-model="profile.company"
              type="text"
              placeholder="Company"
              class="shape col-5"
            ></b-form-input>
          </div>
        </div>
        <div class="col-6">
          <div class="row mt-4">
            <div class="col-3 small-title">Phone</div>
            <b-form-input
              :disabled="true"
              v-model="profile.phoneNumber"
              type="text"
              placeholder="Phone"
              class="shape col-5"
            ></b-form-input>
          </div>
        </div>
        <div class="col-6">
          <div class="row mt-4">
            <div class="col-3 small-title">Country</div>
            <b-form-input
              :disabled="true"
              v-model="profile.country"
              type="text"
              placeholder="Country"
              class="shape col-5"
            ></b-form-input>
          </div>
        </div>
    </div>

      <!-- <div class="title">Billing</div>
      <div class="plus mt-5">
        <div class="plusIn">
          <img src="../assets/plus.png" class="iconPlus" />
          <span> Add Licenses </span>
        </div>
      </div>
      <br /> -->

      <b-modal size="xl" id="profile" hide-footer>
        <createProfile @update-profile="updateProfile" />
      </b-modal>
        <b-modal size="lg" id="changepassword" hide-footer>
        <changePassword @update-password="finishPasswordUpdate" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import api from "../api";
import sidebar from "../components/sidebar.vue";
import upbar from "../components/upbar.vue";
import createProfile from "../components/popup/createProfile.vue";
import changePassword from "../components/popup/changePassword.vue";

export default {
  data() {
    return {
      profile: {},
    };
  },
  components: {
    sidebar,
    upbar,
    createProfile,
    changePassword
  },
  mounted() {
    this.loadAccount();
  },
  methods: {
    finishPasswordUpdate(message){
      this.makeToast('success', 'Password changed', message)
    },
    makeToast(variant, header, textToShow) {
        this.$bvToast.toast(textToShow, {
          title: header ,
          variant: variant,
          toaster: 'b-toaster-top-center',
          solid: false
        })
    },
    loadAccount() {
      api.loadAccount().then(
        (response) => {
          this.profile = response.responseData;
        },
        (error) => {
          alert(JSON.stringify(error));
        }
      );
    },
    updateProfile(form){
      this.profile.firstName = form.firstName;
      this.profile.lastName = form.lastName;
      this.profile.country = form.country;
      this.profile.phoneNumber = form.phoneNumber;
      this.profile.company = form.company;
    }
  },
};
</script>

<style scoped>
.main {
  margin-top: 53px;
  margin-left: 370px;
}

.icon {
  margin-right: 25px;
}
.plus {
  float: right;
  margin-right: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #ffffff;
  width: 268px;
  height: 50px;
  cursor: pointer;
  background: #2a4192;
  border-radius: 5px;
}
.plusIn {
  margin-top: 10px;
  margin-left: 40px;
}
.iconPlus {
  margin-right: 20px;
}
.shape {
  margin-top: -12px;
  width: 250px;
  height: 50px;
}
.title {
  float: left;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.3px;
  margin-top: 48px;
  color: #2a4192;
}
.small-title {
  float: left;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: #2a4192;
}
.top {
  margin-top: 150px;
}
</style>
